import { Timestamp } from "firebase/firestore";

/**
 * Converts a Firebase Timestamp to a formatted date string.
 *
 * @param {Timestamp} timestamp - The Firebase Timestamp to convert.
 * @returns {string} A formatted date string in the format 'YYYY-MM-DD'.
 */

export function convertTimestampToDate(timestamp: Timestamp): string {
  const date = new Date(timestamp.seconds * 1000);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}
