import { Props } from "@/types";
import { Variable } from "@claimsgate/core-types";
import { PropType } from "vue";

export function getProps() {
  return {
    padding: {
      type: String,
      default: "10",
      description: "The padding above the block",
    } as Props.CGPropExtras<string>,
    isBuilder: {
      type: Boolean,
      default: false,
      description: "Whether the block is being used in the builder",
    } as Props.CGPropExtras<boolean>,
    stores: {
      type: Array,
      required: false,
      default: (): Array<{ name: string; type: string }> => [
        { name: "craProductType", type: "string" },
        { name: "craFinanceLender", type: "string" },
        { name: "craCompanyType", type: "string" },
        { name: "craAgreementNumber", type: "string" },
        { name: "craCreditStartDate", type: "date" },
        { name: "craCurrentBalance", type: "string" },
        { name: "craCreditEndDate", type: "date" },
        { name: "craSettlementDate", type: "date" },
        { name: "craAccountStatus", type: "string" },
        { name: "craLengthOfContract", type: "string" },
        { name: "craEstimatedDebt", type: "number" },
        { name: "financeLender", type: "string" },
        { name: "keeperStartDate", type: "date" },
        { name: "enteredRegistration", type: "number" },
        { name: "parentClaimId", type: "string" },
      ],
      description: "The variables stored by this block",
      allowVariable: false,
    } as Props.CGPropExtras<Array<{ name: string; type: string }>>,
    useValidateRunCheck: {
      type: Boolean,
      default: false,
      description: "Whether to use validateRunCheck to run the check",
    } as Props.CGPropExtras<boolean>,
    headless: {
      type: Boolean,
      default: false,
      description: "Whether to run the check in headless mode",
    } as Props.CGPropExtras<boolean>,
    allowCheckboardFallback: {
      type: Boolean,
      default: false,
      description: "Whether to allow the checkboard fallback",
    } as Props.CGPropExtras<boolean>,
    skipApproveCheck: {
      type: Boolean,
      default: false,
      description: "Whether to skip the approve check view",
    } as Props.CGPropExtras<boolean>,
  };
}
