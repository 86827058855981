<script lang="ts">
import { formComputed } from "@/state/helpers";
import Vue, { PropOptions } from "vue";
import { Fragment } from "vue-frag";
import BlockProgress from "./progress.vue";
import BlockSubtitle from "./text/subtitle.vue";
import { ProgressBar, ProgressBarSection } from "@claimsgate/core-types";
import { getProgressProps } from "@/helpers/ClaimsGate/blocks/progressProps";
import { subTitleProps as getSubTitleProps } from "@/helpers/ClaimsGate/blocks/textProps";

// Fetch the properties and create a new copy
const subTitleProps = getSubTitleProps();
const progressProps = getProgressProps();

delete subTitleProps.text;
delete progressProps.value;

export default Vue.extend({
  name: "DynamicProgressBar",
  components: {
    Fragment: Fragment as any,
    BlockProgress,
    BlockSubtitle,
  },
  props: {
    height: {
      ...progressProps.height,
      default: "10",
    },
    variant: {
      ...progressProps.variant,
      default: "primary",
    },
    padding: {
      ...progressProps.padding,
      default: "5",
    },
    animated: {
      ...progressProps.animated,
      default: true,
    },
    subTitlePadding: {
      ...subTitleProps.padding,
      default: "0",
    },
    align: {
      ...subTitleProps.align,
      default: "center",
    },
    textColour: {
      ...subTitleProps.textColour,
      default: "text-primary",
    },
    styles: {
      ...subTitleProps.styles,
      default: "h5",
    },
    weight: {
      ...subTitleProps.weight,
      default: "bold",
    },
    mode: {
      type: String,
      default: "section",
      valid: ["page", "section", "none"],
    } as PropOptions<string>,
    width: {
      ...progressProps.width,
      default: "md",
    } as PropOptions<string>,
    customText: {
      type: String,
      default: "",
    } as PropOptions<string>,
  },
  watch: {
    "$route.params.pageId"(value) {
      this.currentPageId = value;
    },
  },
  data() {
    return {
      currentPageId: this.$route.params.pageId,
    };
  },
  computed: {
    ...formComputed,
    currentPageTitle(): string {
      return this.funnelMetaCache?.pages?.find((page) => page.id === this.currentPageId)?.title;
    },
    currentSectionPages(): ProgressBarSection["pages"] {
      return this.funnelMetaCache?.progressBar?.sections.find((section) => section.pages.includes(this.currentPageId))
        .pages;
    },
    currentSectionTitle(): string {
      const progressBar = this.funnelMetaCache?.progressBar as ProgressBar;
      return progressBar?.sections.find((section) => section.pages.includes(this.currentPageId)).title;
    },
    currentProgress(): number {
      if (this.currentSectionPages?.length) {
        const currentPageIndex = this.currentSectionPages.findIndex((pageId) => pageId === this.currentPageId);

        // if (currentPageIndex === 0) {
        //   return 0;
        // }
        const progress = (currentPageIndex + 1) / (this.currentSectionPages?.length + 1);
        return Math.floor(progress * 100);
      }

      return 0;
    },
    /**Pass props to child */
    progressPropsKeyVals(): Partial<Record<keyof typeof progressProps, any>> {
      const out: Partial<Record<keyof typeof progressProps, any>> = {};
      Object.keys(progressProps).forEach((key) => {
        out[key] = this[key];
      });
      return {
        ...out,
        value: this.currentProgress
          ? this.currentProgress
          : 10 /** if progress is 0, set to 10 so the bar shows a little bit */,
      };
    },
    /**Pass props to child */
    subTitlePropsKeyVals(): Partial<Record<keyof typeof subTitleProps, any>> {
      const out: Partial<Record<keyof typeof subTitleProps, any>> = {};
      Object.keys(subTitleProps).forEach((key) => {
        out[key] = this[key];
      });

      return { ...out, text: this.titleText };
    },
    titleText(): string {
      if (this.mode === "none") {
        return undefined;
      }

      if (this.customText) {
        if (this.currentProgress !== 0 && this.currentProgress !== 100) {
          return `${this.customText} ${this.currentProgress}%`;
        }
      }

      const heading = this.mode === "page" ? this.currentPageTitle : this.currentSectionTitle;
      if (this.currentProgress !== 0 && this.currentProgress !== 100) {
        return `${heading} ${this.currentProgress}%`;
      }

      return heading;
    },
  },
});
</script>
<template>
  <Fragment>
    <BlockSubtitle v-bind="subTitlePropsKeyVals" v-if="titleText" />
    <BlockProgress v-bind="progressPropsKeyVals" padding="5" />
  </Fragment>
</template>
