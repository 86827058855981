<script lang="ts">
import Vue, { VueConstructor } from "vue";
//Components
import { getUser, getClaim } from "@claimsgate/core";
import ArmalytixHeaderContent from "./subcomponents/armalytixHeaderContent.vue";
import ArmalytixAboutCard from "./subcomponents/armalytixAboutCard.vue";
import BlockButton from "@/components/shared/blocks/button.vue";

//Helpers
import { gatewayHelper } from "./helpers/gatewayHelper";
import { navigationComputed, themesComputed } from "@/state/helpers";
import { getFirebaseBackend } from "@/authUtils.js";

//Types
import type { OverdraftReport, Refs } from "./overdraftReportInstance";
import type { Claim } from "@claimsgate/core-types";
import { getUserId } from "@/helpers/vue";

export default (Vue as VueConstructor<OverdraftReport & Refs>).extend({
  name: "ArmalytixOverdraftReport",
  computed: {
    ...navigationComputed,
    ...themesComputed,
    fullName(): string {
      return `${this.user.firstName} ${this.user.lastName}`;
    },
  },
  components: { ArmalytixHeaderContent, ArmalytixAboutCard, BlockButton },
  props: {
    isBuilder: {
      type: Boolean,
      default: false,
    },
    padding: {
      type: String,
      default: "20",
    },
  },
  data() {
    return {
      step: "start" as "start" | "inProgress" | "completed",
      isSubmitting: false,
      errorMessage: "",
      user: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
      },
      checkUrl: "",
      claim: null as Claim | null,
      unsubscribe: null as (() => void) | null,
    };
  },
  methods: {
    firestoreListener() {
      const db = getFirebaseBackend();
      const claimRef = db
        .firestore()
        .collection("users")
        .doc(this.user.id)
        .collection("claims")
        .doc(this.$route.params.claimId);
      this.unsubscribe = claimRef.onSnapshot((doc) => {
        if (doc.exists && doc.data().armalytixReportStatus) {
          this.step = doc.data().armalytixReportStatus;
        } else {
          console.log("No report status found on the claim.");
        }
      });
    },
    async getUserData(state: OverdraftReport): Promise<void> {
      try {
        const userId = getFirebaseBackend().firebaseAuth()?.currentUser?.uid;

        const [user, getUserError] = await getUser(getFirebaseBackend().firestore(), userId);

        if (getUserError || !user) {
          console.error("Error getting user:", getUserError);
          throw getUserError;
        }

        state.user.id = userId;
        state.user.firstName = user.firstName;
        state.user.lastName = user.lastName;
        state.user.email = user.email;

        const [claim, getClaimError] = await getClaim(
          getFirebaseBackend().firestore(),
          userId,
          this.$route.params.claimId
        );

        if (getClaimError || !claim) {
          console.error("Error getting claim:", getClaimError);
          throw getClaimError;
        }

        this.claim = claim;

        if (claim.armalytixReportStatus) this.step = claim.armalytixReportStatus;

        if (claim.armalytixCheckUrl) this.checkUrl = claim.armalytixCheckUrl;
      } catch (error) {
        console.error("Error getting user data:", error);
      }
    },
    async connectBank() {
      if (this.user.firstName && this.user.lastName && this.user.email && this.user.id) {
        await this.getReportRequest();
      } else {
        console.log("Missing user information");
        this.errorMessage = "Please fill in all fields";
      }
    },
    async getReportRequest() {
      try {
        this.isSubmitting = true;

        const tokenObject: any = await gatewayHelper("armalytixGetCachedToken", { userId: this.user.id });
        const token = tokenObject.data;
        const startReportRequest = await gatewayHelper("armalytixReportRequest", {
          token,
          userId: this.user.id,
          userData: {
            id: getUserId(),
            fullName: this.fullName,
            email: this.user.email,
            claimId: this.$route.params.claimId,
          },
        });

        if (!startReportRequest.data) {
          console.error("Error getting report request:", startReportRequest);
          throw new Error("Error getting request URL from Armalytix");
        }

        const bankConnectionUrl = startReportRequest.data;
        this.checkUrl = bankConnectionUrl;

        let loginLink = await gatewayHelper("armalytixGenerateLoginLink", {
          userId: this.user.id,
          claimId: this.$route.params.claimId,
          funnelId: this.$route.params.funnelId,
          pageId: this.$route.params.pageId,
        } as any);

        if (window.location.host.includes("localhost") && loginLink.includes("https://dev-claims-cake.web.app")) {
          loginLink = loginLink.replace("https://dev-claims-cake.web.app", "http://localhost");
        }

        console.log("loginLink", loginLink);
        const redirectUrl = "&responseUrl=" + loginLink;

        window.open(bankConnectionUrl + redirectUrl, "_blank");
      } catch (error) {
        console.error("Error getting report:", error);
      } finally {
        this.isSubmitting = false;
      }
    },
    async resumeCheck() {
      try {
        this.isSubmitting = true;
        let loginLink = await gatewayHelper("armalytixGenerateLoginLink", {
          userId: this.user.id,
          claimId: this.$route.params.claimId,
          funnelId: this.$route.params.funnelId,
          pageId: this.$route.params.pageId,
        } as any);

        if (window.location.host.includes("localhost") && loginLink.includes("https://dev-claims-cake.web.app")) {
          loginLink = loginLink.replace("https://dev-claims-cake.web.app", "http://localhost");
        }

        const redirectUrl = "&responseUrl=" + loginLink;
        window.open(this.checkUrl + redirectUrl, "_blank");
      } catch (error) {
        console.error("Error resuming check:", error);
      } finally {
        this.isSubmitting = false;
      }
    },
    submit() {
      this.isSubmitting = true;
      this.$store.dispatch("events/fire", { name: "next" });
    },
  },
  watch: {
    step: {
      handler() {
        if (this.step === "completed") {
          this.submit();
        }
        window.scrollTo(0, 0);
      },
    },
  },
  async mounted() {
    window.scrollTo(0, 0);

    try {
      await this.getUserData(this);
      this.firestoreListener();
    } catch (error) {
      console.error("mounted - error", error);
    }
  },
  beforeDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
});
</script>
<style scoped>
.gap-20 {
  gap: 20px;
}
.poppins {
  font-family: "Poppins", sans-serif !important;
}
</style>
<template>
  <div>
    <!-- Verification start view  -->
    <div v-if="step === 'start'" class="text-center mx-auto">
      <div class="d-flex flex-column gap-20">
        <div>
          <ArmalytixHeaderContent
            :isBuilder="isBuilder"
            :progressValue="'33'"
            :header="'Securely provide your financial evidence'"
            :subtitle="`<strong>${user.firstName} ${user.lastName}</strong>, based on the information provided, we believe you have a strong case for a claim, and therefore believe you could be eligible for financial compensation.<br /><br />To assess your claim quickly and effectively, we now need to collect some financial evidence to support your claim.
            <br /><br />We are partnered with Armalytix, who are FCA regulated to do this, and they are there to keep your financial data secure. You can learn more about Armalytix here`"
          />
        </div>

        <div class="d-flex flex-column gap-20">
          <video
            controls
            :src="`https://armalytix.com/wp-content/uploads/2021/10/Armalytix-Customer-UHD-4K.mp4`"
            class="my-video"
          >
            Your browser does not support the video tag.
          </video>
        </div>
        <div>
          <ArmalytixAboutCard />
        </div>

        <div class="text-left">
          <h5 class="text-sm text-grey-500 font-weight-normal poppins">
            By clicking "Connect Bank", you will be redirected to Armalytix’s secure platform to connect your bank
            account. This is a secure, read-only, one time process that won't affect your financial standing. You are
            always in full control of your data as you work through this.
          </h5>
        </div>

        <div>
          <BlockButton
            :text="'Connect Bank'"
            :variant="'primary'"
            :isProcessing="isSubmitting"
            @clicked="connectBank"
            :block="false"
            :submitButtonDefault="false"
          />
        </div>
      </div>
    </div>
    <!-- Verificaiton in progress view -->
    <div v-if="step === 'inProgress'" class="text-center mx-auto">
      <div class="d-flex flex-column gap-20">
        <div>
          <ArmalytixHeaderContent
            :isBuilder="isBuilder"
            :progressValue="'66'"
            :header="'Evidence collection in Progress'"
            :subtitle="`<strong>${user.firstName} ${user.lastName}</strong>, we note you have started to provide your financial evidence.<br /><br />If you’ve lost the link to your session, please press Resume to continue securely sharing your financial information with us.<br /><br />If you have completed your Open Banking connection, please wait a moment for our system to register your completion.`"
          />
        </div>

        <BlockButton
          :text="'Resume'"
          :variant="'primary'"
          :isProcessing="isSubmitting"
          @clicked="resumeCheck"
          :block="false"
          :submitButtonDefault="false"
        />
      </div>
    </div>
    <!-- Verification completed view-->
    <div v-if="step === 'completed'" class="text-center mx-auto">
      <div class="d-flex flex-column gap-20">
        <div>
          <ArmalytixHeaderContent
            :isBuilder="isBuilder"
            :progressValue="'99'"
            :header="'Evidence collection completed'"
            :subtitle="`<strong>${user.firstName} ${user.lastName}</strong>, thank you for sharing your financial evidence with us.<br /><br />Please press continue to proceed with your claim.`"
          />
        </div>

        <BlockButton
          :text="'Continue'"
          :variant="'primary'"
          :isProcessing="isSubmitting"
          @clicked="submit"
          :block="false"
          :submitButtonDefault="false"
        />
      </div>
    </div>
    <!-- Assuming this view will never be shown, as a user will always have first and last name -->
    <!-- <div class="text-center mx-auto">
      <div class="d-flex flex-column gap-20">
        <div>
          <ArmalytixHeaderContent
            :isBuilder="isBuilder"
            :progressValue="'33'"
            :header="'Verify Your Financial Agreement'"
            :subtitle="`We require the following information to start your credit check`"
          />
        </div>

        <SimpleName :title="false" :middleName="false" :answer="user" ref="simpleName" padding="5" />

        <BlockButton
          :text="'Submit'"
          :variant="'primary'"
          :isProcessing="isSubmitting"
          :block="false"
          :submitButtonDefault="false"
        />
      </div>
    </div> -->
  </div>
</template>
