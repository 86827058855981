var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-column gap-20"},[_c('CheckboardHeaderContent',{attrs:{"isBuilder":_vm.isBuilder,"progressValue":'66',"header":"Verify Your Identity","subtitle":`<strong>${_vm.user.firstName} ${_vm.user.lastName}</strong>, we need you to answer a few financial questions about yourself to verify your identity.`,"text":`Your answers will help us to confirm that you are the rightful claimant.`}}),_c('div',[_c('BlockSingleSelect',_vm._b({ref:"question1",on:{"update:answer":function($event){return _vm.handleAnswer(1)}}},'BlockSingleSelect',{
        options: _vm.questionnaire.questions[0].choices.map((choice) => choice.choiceText),
        label: _vm.questionnaire.questions[0].questionText,
        state: _vm.answerState['1'],
        invalidFeedback: _vm.invalidFeedback,
        padding: '0',
      },false))],1),_c('div',[_c('BlockSingleSelect',_vm._b({ref:"question2",on:{"update:answer":function($event){return _vm.handleAnswer(2)}}},'BlockSingleSelect',{
        options: _vm.questionnaire.questions[1].choices.map((choice) => choice.choiceText),
        label: _vm.questionnaire.questions[1].questionText,
        state: _vm.answerState['2'],
        invalidFeedback: _vm.invalidFeedback,
        padding: '0',
      },false))],1),_c('div',[_c('BlockSingleSelect',_vm._b({ref:"question3",on:{"update:answer":function($event){return _vm.handleAnswer(3)}}},'BlockSingleSelect',{
        options: _vm.questionnaire.questions[2].choices.map((choice) => choice.choiceText),
        label: _vm.questionnaire.questions[2].questionText,
        state: _vm.answerState['3'],
        invalidFeedback: _vm.invalidFeedback,
        padding: '0',
      },false))],1),_c('BlockButton',{attrs:{"text":'Submit',"variant":'primary',"isProcessing":_vm.isSubmitting,"block":false,"submitButtonDefault":false},on:{"clicked":_vm.handleSubmit}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }