import { onCallGateway } from "@/helpers/ClaimsGate/onCallGateway";
import type { FunctionName } from "./CheckboardCreditCheck";
import type { Checkboard } from "@claimsgate/core-types";

export async function gatewayHelper<T extends FunctionName>(
  functionName: T,
  data: Checkboard.Gateway.FunctionsRequests[T]
): Promise<Checkboard.Gateway.FunctionsResponses[T]> {
  console.log("gatewayHelper calling: ", functionName, "with data: ", data);
  const result = await onCallGateway({
    functionName: functionName as any,
    data,
  });
  console.log("gatewayHelper result", result);
  if (result.data?.error || !result.data?.data) {
    throw new Error(
      `OnCallGateway error - function: ${functionName}; error: ${JSON.stringify(result.data?.error)} ${JSON.stringify(
        result
      )}
      )}`
    );
  }

  return result.data.data;
}
