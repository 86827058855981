import { Checkboard } from "@claimsgate/core-types";
import { viewSubmit } from "./viewSubmit";

type State = Vue;

export async function handleUnknownError(state: State, check: Checkboard.Check) {
  viewSubmit(state, "fail", {
    clientView: "fail",
    check,
  });
}
