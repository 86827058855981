<script lang="ts">
import Vue from "vue";
import BlockLoadingBar from "@/components/shared/layout/loadingBar.vue";

export default Vue.extend({
  name: "InitiateCheck",
  components: {
    BlockLoadingBar,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
});
</script>

<style scoped>
/* .vh-100 {
  height: 100vh;
} */
</style>

<template>
  <div class="d-flex align-items-center justify-content-center">
    <BlockLoadingBar :loading="true" :alignCenter="true" />
  </div>
</template>
