<script lang="ts">
import { getData } from "./data";
import { getProps } from "./props";
import { computed } from "./computed";
import * as methods from "./methods";
import Vue from "vue";
import BlockInputWrapper from "../inputwrapper.vue";
import BlockInput from "../input.vue";
import BlockPadding from "../padding.vue";
import CardHeader from "@/components/shared/styleWrappers/cardHeader/cardHeader.vue";
import { UserDataService } from "@/helpers/ClaimsGate/DataService";

export default Vue.extend({
  name: "BlockDataArray",
  components: {
    BlockInputWrapper,
    BlockInput,
    BlockPadding,
    CardHeader,
  },
  props: {
    ...getProps(),
  },
  data() {
    return {
      ...getData(this),
    };
  },
  computed: {
    componentList(): object {
      return computed.componentList(this);
    },
    buttonText(): string {
      return computed.buttonText(this);
    },
    addText(): string {
      return computed.addText(this);
    },
    validationClasses(): string {
      return computed.validationClasses(this);
    },
  },
  methods: {
    handlePlaceSelected(
      place: { addressLine1: string; city: string; postcode: string; country: string },
      inputAnswer: Array<any>,
      index: number
    ) {
      console.log("place selected", place);

      this.$set(this.inputAnswer[index], this.getFieldId("currentAddressLine1"), place.addressLine1);
      this.$set(this.inputAnswer[index], this.getFieldId("currentCity"), place.city);
      this.$set(this.inputAnswer[index], this.getFieldId("currentPostcode"), place.postcode);
      this.$set(this.inputAnswer[index], this.getFieldId("currentCountry"), place.country);

      console.log("inputAnswer", this.inputAnswer);
    },
    generateInitialBlocks(): object[] {
      return methods.generateInitialBlocks(this);
    },
    async validations(): Promise<boolean> {
      return methods.validations(this);
    },
    async computes(): Promise<void> {
      return methods.computes(this);
    },
    async saveEntity(index: number): Promise<void> {
      return methods.saveEntity(index, this);
    },
    removeEntity(index: number): void {
      return methods.removeEntity(index, this);
    },
    async addEntity(): Promise<void> {
      return methods.addEntity(this);
    },
    editEntity(index: number): void {
      return methods.editEntity(index, this);
    },
    roundingClass(index: number): string {
      return methods.roundingClass(index, this);
    },
    getValidationClasses(index: number): string {
      return methods.getValidationClasses(index, this);
    },
    getFieldId(field: string): string {
      return methods.getFieldId(field, this);
    },
    deleteIncompleteEntityAnswer(index: number): void {
      return methods.deleteIncompleteEntityAnswer(index, this);
    },
  },

  mounted() {
    try {
      if (this.answer) {
        console.log("DataArray mounted with answer", this.answer);
        this.inputAnswer = this.answer;
        this.inputGroups = [];
        this.inputAnswer.forEach(() => {
          this.inputGroups.push(this.generateInitialBlocks());
        });
      } else {
        this.inputGroups = [this.generateInitialBlocks()];
      }

      if (this.entityName === "Party") {
        this.userDataService = new UserDataService(this.userId);

        const artefacts = [
          "dateOfBirth",
          "firstName",
          "lastName",
          "title",
          "addressLine1",
          "postcode",
          "city",
          "country",
        ];

        const artefactMapping = {
          addressLine1: "currentAddressLine1",
          postcode: "currentPostcode",
          city: "currentCity",
          country: "currentCountry",
        };

        artefacts.forEach((artefact) => {
          const fieldId = this.getFieldId(artefactMapping[artefact] || artefact);
          const value = this.userDataService.getArtefact(artefact);
          if (value) {
            this.$set(this.inputAnswer[0], fieldId, value);
          }
        });
      }
    } catch (exception) {
      console.error("Error mounting DataArray", exception);
    }
  },

  watch: {
    mode() {
      this.inputGroups = [this.generateInitialBlocks()];
    },
  },
});
</script>
<style scoped>
.grow {
  flex-grow: 1;
}
.rounded-top {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.square-bottom {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.square-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
<template>
  <BlockInputWrapper v-bind="{ padding, label, info }">
    <template v-for="(inputGroup, index) of inputGroups">
      <b-modal
        :id="'inputGroupModal' + index + entityName"
        :key="index + 'group'"
        :no-close-on-backdrop="true"
        :no-close-on-esc="true"
      >
        <template #modal-header="{ close }">
          <CardHeader :heading="addText" :supportingText="'Please complete the form below'">
            <template v-slot:right>
              <b-button size="lg" variant="link" @click="close()" class="p-0 pr-1">
                <i class="fas fa-times mx-1" />
              </b-button>
            </template>
          </CardHeader>
        </template>
        <template v-for="(input, inputIndex) of inputGroup">
          <template v-if="input.blockType !== 'BlockPlaces'">
            <component
              :is="componentList[input.blockType]"
              :key="inputIndex + 'element' + input.field"
              v-bind.sync="input.props"
              :answer.sync="inputAnswer[index][getFieldId(input.field)]"
              :padding="inputIndex === 0 ? '5' : '20'"
              :ref="input.field"
            />
          </template>
          <template v-else>
            <!-- Since we need to render Block Places, we're actually going to render a bunch of BlockInputs to handle this shit -->

            <!-- <component
              :is="componentList[input.blockType]"
              :key="inputIndex + 'element' + input.field"
              v-bind.sync="input.props"
              :answer.sync="inputAnswer[index][getFieldId(input.field)]"
              :existingValue.sync="inputAnswer[index][getFieldId(input.field)]"
              @placeSelected="(e) => handlePlaceSelected(e, inputAnswer, index)"
              :padding="inputIndex === 0 ? '5' : '20'"
              :ref="input.field"
            /> -->
          </template>
        </template>
        <template #modal-footer="{ cancel }">
          <div class="d-flex w-100">
            <div class="grow">
              <b-button size="md" variant="light" @click="cancel()" class="w-100 mr-1"> Cancel </b-button>
            </div>

            <div class="grow">
              <b-button size="md" variant="primary " @click="saveEntity(index)" class="w-100 ml-1">
                <template v-if="uiToggles.isEntityValidating">
                  <b-spinner class="spinner-border-sm" role="status"></b-spinner>
                </template>
                <template v-else>
                  <b-icon icon="plus"></b-icon>
                  {{ buttonText }}
                </template>
              </b-button>
            </div>
          </div>
        </template>
      </b-modal>
      <!-- render -->
      <template>
        <b-card
          :key="'existingElement' + index"
          :class="['p-0', 'm-0', roundingClass(index), getValidationClasses(index)]"
        >
          <div class="d-flex align-items-center">
            <div class="grow">
              <h5 class="mb-0">
                <template
                  v-if="
                    inputAnswer[index][getFieldId('title')] &&
                    inputAnswer[index][getFieldId('firstName')] &&
                    inputAnswer[index][getFieldId('lastName')]
                  "
                >
                  <span class="font-weight-semibold">
                    {{ inputAnswer[index][getFieldId("title")] }}
                    {{ inputAnswer[index][getFieldId("firstName")] }}
                    {{ inputAnswer[index][getFieldId("lastName")] }}
                  </span>
                </template>
                <template v-else>
                  <span class="font-weight-semibold"> {{ entityName }} {{ index + 1 }}</span>
                </template>
              </h5>
            </div>
            <div v-if="index > 0">
              <b-button size="md" variant="outline-light text-grey-600" @click="removeEntity(index)" class="mr-1">
                Remove
              </b-button>
            </div>
            <div>
              <b-button
                size="md"
                variant="outline-primary"
                class="bg-primary-50 text-primary-700 border-primary-50 bg-hover-primary-100 font-weight-semibold"
                @click="editEntity(index)"
              >
                Edit
              </b-button>
            </div>
          </div>
        </b-card>
      </template>
    </template>
    <b-button size="lg" variant="link" @click="addEntity()">
      <b-icon icon="plus"></b-icon>
      {{ addText }}
    </b-button>
    <template v-if="isValid === false">
      <p class="mb-0 text-danger text-sm" v-if="inputAnswer.length <= 1">This field is required</p>
      <p class="mb-0 text-danger text-sm" v-else>All items must be completed or removed</p>
    </template>
  </BlockInputWrapper>
</template>
