import { Checkboard } from "@claimsgate/core-types";

export function getUserDetailsFromCheck(check: Checkboard.Check) {
  if (!check.userData) {
    return {
      firstName: "",
      lastName: "",
      dob: "",
    };
  }
  return {
    firstName: check.userData.firstName,
    lastName: check.userData.lastName,
    dob: check.userData.dob,
  };
}
