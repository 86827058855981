<script lang="ts">
import Vue, { PropType, VueConstructor } from "vue";
// Components
import BlockMultiSelect from "@/components/shared/blocks/multiselect.vue";
import BlockSingleSelect from "@/components/shared/blocks/singleselect.vue";
import BlockButton from "@/components/shared/blocks/button.vue";
import CheckboardHeaderContent from "./checkboardHeaderContent.vue";
import { ClaimDataService } from "@/helpers/ClaimsGate/DataService";
// Helpers
import { baseProps } from "../helpers/baseProps";
import { UserVariableBlocksService } from "@/helpers/ClaimsGate/UserVariableBlocksService";
import { gatewayHelper } from "../helpers/gatewayHelper";
import { viewSubmit } from "../helpers/viewSubmit";
import { handleUnknownError } from "../helpers/handleUnknownError";
import { getUserDetailsFromCheck } from "../helpers/getUserDetailsFromCheck";
import { FunnelsService } from "@claimsgate/core";
import { getFirebaseBackend } from "@/authUtils";
import { getClaimDataService } from "@/helpers/vue";
import { DateService } from "@/helpers/ClaimsGate/DateService";
import { createClaimStore } from "../helpers/createClaimStore";
import { getCheckboardVariableIds, CheckboardVariableIds, variableIds } from "../helpers/getCheckboardVariableIds";
// Types
import type { ConfirmLenders, Account } from "../helpers/CheckboardCreditCheck";
import { Claim } from "@claimsgate/core-types";
const noneOfTheAbove = "None of the above";
const dateService = new DateService();

export default (Vue as VueConstructor<Vue & ConfirmLenders.Props & ConfirmLenders.Refs>).extend({
  name: "confirmLenders",
  components: { BlockMultiSelect, BlockSingleSelect, BlockButton, CheckboardHeaderContent },
  props: {
    ...baseProps(),
    possibleLenders: {
      type: Array as PropType<Array<Account>>,
      required: true,
    },
    allowMapAgreementsToExistingClaims: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    errorCountText(): string {
      if (this.errorCount === 1) {
        return "There is 1 error on the page";
      } else {
        return `There are ${this.errorCount} errors on the page`;
      }
    },
    /**
     * Should the user be able to map other lenders to their other claims
     */
    renderClaimMap(): boolean {
      if (!this.allowMapAgreementsToExistingClaims) return false;
      return this.otherLenderOptions.length > 0 && Object.keys(this.otherClaims).length > 0;
    },
    user(): ReturnType<typeof getUserDetailsFromCheck> {
      return getUserDetailsFromCheck(this.check);
    },
    /**
     * A list of all lenders, minus the selected lender for this claim
     */
    otherLenderOptions(): string[] {
      if (!this.selectedClaimLender && !this.selectedClaimLenderAccount)
        return this.mapAccountToNameList(this.possibleLenders);

      if (this.selectedClaimLender === noneOfTheAbove) return this.mapAccountToNameList(this.possibleLenders);

      /**
       * Remove the lender that is selected for this claim
       */
      const otherLenders = this.possibleLenders.filter(
        (lender) => lender.companyName !== this.selectedClaimLenderAccount?.companyName
      );

      if (otherLenders.length === 0) return [];

      return this.mapAccountToNameList(otherLenders);
    },
    /**
     * All possible lenders, based on the credit check
     */
    claimLenderOptions(): string[] {
      return [...this.mapAccountToNameList(this.possibleLenders), noneOfTheAbove];
    },
    /**
     * Lenders not selected  or for other claims
     */
    unselectedLenders(): string[] {
      const accountNamesSelectedForOtherClaims = Object.entries(this.accountToOtherClaimsMap).map(
        (entries) => entries[1]
      );
      /**
       * Now we have the account names selected for other claims, we can filter out the lenders that have been selected for other claims
       */
      return [
        ...this.otherLenderOptions.filter((lender) => !accountNamesSelectedForOtherClaims.includes(lender)),
        noneOfTheAbove,
      ];
    },
  },
  data() {
    return {
      funnelId: this.check.funnelId,
      selectedClaimLender: "",
      selectedClaimLenderAccount: undefined as Account | undefined,
      otherLenders: [] as string[],
      errorMessage: "Please select an option",
      isSubmitting: false,
      errorCount: 0,
      BlockProps: {
        claimLender: UserVariableBlocksService.genBlockSingleSelectProps({
          padding: "0",
          options: [],
          label: "Please select the finance lender you are claiming for",
        }),
        otherLenders: UserVariableBlocksService.genBlockMultiselectProps({
          padding: "0",
          label: "Please select your other finance lenders from this list",
          info: "These are the finance lenders that you may have used in the past",
        }),
      },
      state: {
        claimLender: null,
        otherLenders: null,
        otherClaims: {},
      },
      errorMessages: {
        claimLender: "",
        otherClaims: {},
        otherLenders: "",
      },
      funnelsService: new FunnelsService(getFirebaseBackend().firestore()),
      claimDataService: null as ClaimDataService | null,
      dateService,
      funnelVariables: [],
      variableIds: variableIds as CheckboardVariableIds,
      claimData: {} as Claim,
      db: getFirebaseBackend().firestore(),

      /**
       * Claims this user has on this funnel, that  don't have a confirmed lender set on them
       */
      otherClaims: {} as { [reg: string]: Claim },
      /**
       * The user maps returned finance account names to their `otherClaims`, by reg
       */
      accountToOtherClaimsMap: {} as { [reg: string]: string },
    };
  },
  watch: {
    selectedClaimLender(newVal) {
      if (!newVal) return;

      this.resetState();

      if (newVal === noneOfTheAbove) {
        this.selectedClaimLenderAccount = undefined;
        return;
      }

      this.selectedClaimLenderAccount = this.possibleLenders.find((lender) => this.parseLenderName(lender) === newVal);

      this.runDupeSelectValidation();
    },
    unselectedLenders() {
      /**
       * Reset the multiselect component so it renders the actual selection,
       * rather than the previous selection. Parents know best
       */
      (this.$refs.otherLenders as any)?.mount();
      (this.$refs.otherLenders as any)?.$forceUpdate();
      this.otherLenders = [];
    },
    otherLenders() {
      this.resetState();
    },
    accountToOtherClaimsMap: {
      deep: true,
      immediate: true,
      handler() {
        this.runDupeSelectValidation();
      },
    },
  },
  methods: {
    reMountClaimLender() {
      console.log("reMountClaimLender", this.$refs.claimLender);
      this.$nextTick(() => {
        (this.$refs.claimLender as any)?.mount();
      });
    },
    createLenderQuestion(claim: Claim): string {
      const [make, model, registration] = [
        claim[this.variableIds.make],
        claim[this.variableIds.model],
        claim[this.variableIds.enteredRegistration] ?? claim[this.variableIds.vehicleRegistration],
      ];
      if (make && model && registration) {
        return `Please select the finance lender used for your ${make ?? "_"}, ${model ?? "_"} with registration ${
          registration ?? "_"
        }`;
      }

      if (registration) {
        return `Please select the finance lender used for your vehicle with registration ${registration}`;
      }

      return "Please select the finance lender used for your vehicle";
    },
    mapAccountToNameList(accounts: Account[]): string[] {
      return accounts.map((lender) => this.parseLenderName(lender));
    },
    parseLenderName(lender: Account) {
      return `${lender.companyName} - (${dateService.toString(lender.start)})`;
    },
    /**
     * Get the account objects for the lenders that have been mapped to other claims
     */
    parseMappedLenders(): Account[] {
      const mappedLenders: Account[] = [];

      Object.values(this.accountToOtherClaimsMap).forEach((lender) => {
        if (lender == noneOfTheAbove) return;

        const lenderAccount = this.possibleLenders.find(
          (possibleLender) => this.parseLenderName(possibleLender) === lender
        );

        if (!lenderAccount) return;

        mappedLenders.push(lenderAccount);
      });

      return mappedLenders;
    },
    /**
     * Get the accounts for the lenders selected in the multiselect component
     */
    parseSelectedOtherLenders(): Account[] {
      const selectedOtherLenders = this.otherLenders
        .filter((selectedLender) => selectedLender !== noneOfTheAbove)
        .map((selectedLender) =>
          this.possibleLenders.find((possibleLender) => this.parseLenderName(possibleLender) === selectedLender)
        );

      return selectedOtherLenders;
    },
    /**
     * Set the account values on the other claims that have been mapped to other lenders
     */
    async updateOtherClaimsFromMap() {
      const claimStores: { [claimId: string]: any } = {};

      Object.entries(this.otherClaims).forEach(([reg, claim]) => {
        if (!this.accountToOtherClaimsMap[reg]) return;

        const claimLender = this.possibleLenders.find(
          (lender) => this.parseLenderName(lender) === this.accountToOtherClaimsMap[reg]
        );

        if (!claimLender) return;

        const claimStore = createClaimStore({
          confirmedClaimLender: claimLender,
          confirmedLenders: [],
          variableIds: this.variableIds,
          creditService: this.check.creditService,
        });

        claimStores[claim.documentId] = claimStore;
      });

      await Promise.all(
        Object.entries(claimStores).map(([claimId, claimStore]) => {
          return this.db
            .collection("users")
            .doc(this.userId)
            .collection("claims")
            .doc(claimId)
            .update(claimStore, { merge: true });
        })
      );
    },
    async storeClaimStore(claimStore: { [key: string]: any }) {
      Object.keys(claimStore).forEach((key) => {
        if (!!key && claimStore[key]) {
          this.claimDataService.setArtefact(key, claimStore[key]);
        }
      });

      await this.claimDataService.update();
    },
    resetState() {
      this.state.claimLender = null;
      this.state.otherLenders = null;

      Object.keys(this.state.otherClaims).forEach((reg) => {
        this.state.otherClaims[reg] = null;
      });
      this.errorCount = 0;
    },
    async submit() {
      this.isSubmitting = true;
      try {
        this.resetState();

        this.errorCount = 0;

        if (!this.selectedClaimLender) {
          this.state.claimLender = false;

          this.isSubmitting = false;
          this.errorCount++;
        } else {
          this.state.claimLender = true;
        }

        if (this.renderClaimMap) {
          /**
           * Validate the claim map
           */

          Object.keys(this.state.otherClaims).forEach((reg) => {
            if (this.accountToOtherClaimsMap[reg]) {
              this.state.otherClaims[reg] = true;
            } else {
              this.errorMessages.otherClaims[reg] = "Please select a lender for this vehicle";
              this.state.otherClaims[reg] = false;
            }
          });

          if (Object.values(this.state.otherClaims).some((value) => value === false)) {
            this.errorCount++;
          }

          const errors = this.runDupeSelectValidation();

          if (errors.length > 0) {
            this.errorCount += errors.length;
          }
        }

        if (this.unselectedLenders.length > 1 && this.otherLenders.length === 0) {
          this.state.otherLenders = false;
          this.errorCount++;
        }

        if (this.errorCount > 0) {
          this.isSubmitting = false;
          return;
        }

        this.errorCount = 0;

        const confirmedLenders = this.parseSelectedOtherLenders();

        if (this.renderClaimMap) {
          confirmedLenders.push(...this.parseMappedLenders());
        }

        //! TESTING
        //const a = 1;
        //if (a == 1) {
        //  console.log(
        //    "TESTING BREAK",
        //    JSON.parse(JSON.stringify(this.createClaimStore(this.selectedClaimLenderAccount, confirmedLenders)))
        //  );
        //  return;
        //}

        const claimStore = createClaimStore({
          confirmedClaimLender: this.selectedClaimLenderAccount,
          confirmedLenders,
          variableIds: this.variableIds,
          creditService: this.check.creditService,
        });

        await this.storeClaimStore(claimStore);

        if (this.renderClaimMap && this.parseMappedLenders().length > 0) {
          await this.updateOtherClaimsFromMap();
        }

        const result = await gatewayHelper("checkboardConfirmLender", {
          claimId: this.claimId,
          userId: this.userId,
          checkId: this.checkId,
          confirmedClaimLender: this.selectedClaimLenderAccount,
          confirmedLenders: this.parseSelectedOtherLenders(), // claims created from this, so  don't want to include mapped lenders
        });

        viewSubmit(this, "confirmLenders", result);
      } catch (error) {
        console.error(`Error submitting lenders: ${error}`);
        handleUnknownError(this, this.check);
      } finally {
        this.isSubmitting = false;
      }
    },
    async getOtherClaims(): Promise<{ [reg: string]: Claim }> {
      try {
        const claims = await this.db
          .collection("users")
          .doc(this.userId)
          .collection("claims")
          .where("currentFunnelId", "==", this.funnelId)
          .where("clientClaimProgress", "==", "inProgress")
          .get();

        const claimsWithNoConfirmedLenderByReg = {};

        claims.docs.map((claim) => {
          const otherClaim = claim.data() as Claim;

          if (otherClaim[this.variableIds.craFinanceLender] || otherClaim[this.variableIds.craAgreementNumber])
            return null;

          const reg =
            otherClaim[this.variableIds.enteredRegistration] ?? otherClaim[this.variableIds.vehicleRegistration];
          console.log("reg", reg, otherClaim.documentId);
          if (!reg) return null;

          if (
            reg === this.claimData[this.variableIds.enteredRegistration] ||
            reg === this.claimData[this.variableIds.vehicleRegistration]
          )
            return null;

          claimsWithNoConfirmedLenderByReg[reg] = otherClaim;
        });

        return claimsWithNoConfirmedLenderByReg;
      } catch (error) {
        console.error(`Error fetching other claims: ${error}`);
        return {};
      }
    },
    async getVariables() {
      return await getCheckboardVariableIds({ funnelService: this.funnelsService, funnelId: this.funnelId });
    },
    /**
     * When the user is mapping claims, we need to ensure a lender is not selected twice
     */
    runDupeSelectValidation() {
      const errors = [];
      Object.entries(this.accountToOtherClaimsMap).forEach(([reg, lender]) => {
        if (lender === noneOfTheAbove) {
          this.state.otherClaims[reg] = null;
          this.errorMessages.otherClaims[reg] = "";
          return;
        }

        const otherSelections = Object.entries(this.accountToOtherClaimsMap)
          .filter(([r]) => r != reg)
          .map((o) => o[1]);

        if (otherSelections.includes(lender) || lender === this.selectedClaimLender) {
          this.state.otherClaims[reg] = false;
          this.errorMessages.otherClaims[reg] = "This lender has already been selected for another vehicle";
          errors.push(reg);
        } else {
          this.state.otherClaims[reg] = null;
          this.errorMessages.otherClaims[reg] = "";
        }
      });

      return errors;
    },
    async mapLendersToExistingClaims() {
      // first, try to match a lender to the current claim
      const { possibleLenders } = this;

      let matchLenderForCurrentClaim = null;

      const keeperStart = this.claimData[this.variableIds.keeperStartDate];

      if (keeperStart) {
        possibleLenders.forEach((lender) => {
          const agreementStart = lender.start;

          const agreementStartDate = new Date(agreementStart);
          const keeperStartDate = this.dateService.parseDate(keeperStart);

          const keeperStartDateYear = keeperStartDate.getFullYear();
          const keeperStartDateMonth = keeperStartDate.getMonth();

          const agreementStartDateYear = agreementStartDate.getFullYear();
          const agreementStartDateMonth = agreementStartDate.getMonth();

          const yearMatch = agreementStartDateYear === keeperStartDateYear;

          const monthMatch =
            agreementStartDateMonth === keeperStartDateMonth ||
            agreementStartDateMonth === keeperStartDateMonth + 1 ||
            agreementStartDateMonth === keeperStartDateMonth - 1;

          let previousYearMatch = false;
          if (agreementStartDateMonth === 1) {
            previousYearMatch = agreementStartDateYear - 1 === keeperStartDateYear && keeperStartDateMonth === 12;
          }

          let nextYearMatch = false;
          if (agreementStartDateMonth === 12) {
            nextYearMatch = agreementStartDateYear + 1 === keeperStartDateYear && keeperStartDateMonth === 1;
          }

          if ((monthMatch && yearMatch) || previousYearMatch || nextYearMatch) {
            matchLenderForCurrentClaim = lender;
          }
        });
      }

      if (matchLenderForCurrentClaim) {
        this.selectedClaimLender = this.parseLenderName(matchLenderForCurrentClaim);
        this.reMountClaimLender();
      }

      const remainingLenders = possibleLenders.filter((lender) => lender !== matchLenderForCurrentClaim);

      if (Object.keys(this.otherClaims).length === 0) {
        //I don't think we should do this
        //this.otherLenders = remainingLenders.map((lender) => this.parseLenderName(lender));
        return;
      }

      const accountToOtherClaimsMap: { [reg: string]: string } = {};

      const unusedLenders = [];

      remainingLenders.forEach((lender) => {
        const agreementStart = lender.start;
        const agreementStartDate = new Date(agreementStart);
        const agreementStartDateMonth = agreementStartDate.getMonth();
        const agreementStartDateYear = agreementStartDate.getFullYear();

        Object.keys(this.otherClaims).forEach((reg) => {
          const keeperStart = this.otherClaims[reg][this.variableIds.keeperStartDate];

          if (!keeperStart) return;

          const keeperStartDate = new Date(keeperStart);
          const keeperStartDateMonth = keeperStartDate.getMonth();
          const keeperStartDateYear = keeperStartDate.getFullYear();

          if (agreementStartDateMonth === keeperStartDateMonth && agreementStartDateYear === keeperStartDateYear) {
            accountToOtherClaimsMap[reg] = this.parseLenderName(lender);
          } else {
            unusedLenders.push(lender);
          }
        });
      });

      // I don't think we should do this
      //if (unusedLenders.length > 0) {
      //  this.otherLenders = unusedLenders.map((lender) => this.parseLenderName(lender));
      //}
    },
  },
  async mounted() {
    try {
      window.scrollTo(0, 0);

      await getClaimDataService(this);

      this.claimData = this.claimDataService.getCache();

      await this.getVariables();

      this.otherClaims = await this.getOtherClaims();

      await this.mapLendersToExistingClaims();

      Object.keys(this.otherClaims).forEach((reg) => {
        this.state.otherClaims[reg] = null;
      });
    } catch (error) {
      console.error(`check:confirmLenders:mounted: ${error}`);
      handleUnknownError(this, this.check);
    }
  },
});
</script>
<style scoped>
.gap-20 {
  gap: 20px;
}
.gap-16 {
  gap: 16px;
}
</style>
<template>
  <div class="d-flex flex-column gap-20">
    <CheckboardHeaderContent
      :isBuilder="isBuilder"
      :progressValue="'75'"
      :header="'Confirm Your Finance Lender'"
      :subtitle="`<strong>${user.firstName} ${user.lastName}</strong>, please confirm the details we have retrieved about your finance lender.`"
    />

    <BlockSingleSelect
      ref="claimLender"
      v-bind="BlockProps.claimLender"
      :label="createLenderQuestion(claimData)"
      :state="state.claimLender"
      :options="claimLenderOptions"
      :invalidFeedback="errorMessage"
      :answer.sync="selectedClaimLender"
      :padding="'10'"
    />

    <!-- Render a singles select for each of the claims this user has, that don't have a finance agreement defined -->
    <template v-if="renderClaimMap">
      <div v-for="(otherClaim, reg) in otherClaims" :key="reg" class="d-flex flex-column gap-20">
        <BlockSingleSelect
          v-bind="BlockProps.otherLenders"
          :label="createLenderQuestion(otherClaim)"
          :options="claimLenderOptions"
          :answer.sync="accountToOtherClaimsMap[reg]"
          :padding="'10'"
          :state="state.otherClaims[reg]"
          :invalidFeedback="errorMessages.otherClaims[reg]"
        />
      </div>
    </template>

    <template v-if="unselectedLenders.length > 1">
      <BlockMultiSelect
        ref="otherLenders"
        v-bind="BlockProps.otherLenders"
        :options="unselectedLenders"
        :state="state.otherLenders"
        :invalidFeedback="errorMessage"
        :answer.sync="otherLenders"
        :padding="'10'"
      />
    </template>

    <BlockButton
      :text="'Submit'"
      :variant="'primary'"
      :isProcessing="isSubmitting"
      @clicked="submit"
      :block="false"
      :submitButtonDefault="false"
      :padding="'10'"
    />
    <template v-if="errorCount > 0">
      <div class="text-center">
        <h5 class="text-danger">{{ errorCountText }}</h5>
      </div>
    </template>
  </div>
</template>
