// Top level redirects for SPA
if (window.location.hostname === "api.claimsgate.co.uk") {
  window.location.pathname = "v1/docs/";
}

// Importing Vue
// Testing manual trigger again
import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import VueMask from "v-mask";
import VueRouter from "vue-router";
import router from "./router.js";
import "@/design/index.scss";
import store from "@/state/store";

import App from "./components/shared/app/App.vue";
import VueGtag from "vue-gtag";
import VueIntercom from "@mathieustan/vue-intercom";
import VueSignaturePad from "vue-signature-pad";
import x5GMaps from "x5-gmaps";

// Import block components
import BlockDisplayFile from "@/components/shared/blocks/displayFile/displayFile.vue";
import BlockCurrency from "@/components/shared/blocks/currency/currency.vue";
import BlockCountry from "@/components/shared/blocks/country/country.vue";
import BlockKyb from "@/components/shared/blocks/kyb/kyb.vue";
import BlockText from "@/components/shared/blocks/text/text.vue";
import BlockButton from "@/components/shared/blocks/button.vue";
import BlockPageButtons from "@/components/shared/blocks/pagebuttons.vue";
import BlockCarouselWidget from "@/components/shared/blocks/carouselWidget.vue";
// import BlockEmbed from "@/components/shared/blocks/embed";
import BlockImage from "@/components/shared/blocks/image.vue";
import BlockProgressBar from "@/components/shared/blocks/progress.vue";
import BlockInput from "@/components/shared/blocks/input.vue";
import BlockFileInput from "@/components/shared/blocks/fileinput.vue";
import BlockMultiFileInput from "@/components/shared/blocks/multiFileInput.vue";
import BlockVideo from "@/components/shared/blocks/video.vue";
import BlockHTML from "@/components/shared/blocks/html.vue";
import BlockDropdown from "@/components/shared/blocks/dropdown.vue";
import BlockDatepicker from "@/components/shared/blocks/datepicker.vue";
import BlockDropdownSearch from "@/components/shared/blocks/dropdownSearchV3/dropdownSearchV3.vue";
import BlockSingleSelect from "@/components/shared/blocks/singleselect.vue";
import BlockMultiSelect from "@/components/shared/blocks/multiselect.vue";
import BlockCheckbox from "@/components/shared/blocks/checkbox.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockCompany from "@/components/shared/blocks/company/company.vue";
import BlockClaimAddress from "@/components/shared/blocks/claimAddress/claimAddress.vue";
import BlockVehicle from "@/components/shared/blocks/utility/vehicle.vue";
import BlockCaravan from "@/components/shared/blocks/caravanBlock/caravanBlock.vue";
import BlockAltVehicle from "@/components/shared/blocks/altVehicleBlock/altVehicleBlock.vue";
import BlockMotorSpecs from "@/components/shared/blocks/motorSpecs/motorSpecs.vue";
import BlockRevX from "@/components/shared/blocks/revXVehicle/revX.vue";
import BlockClaimsGateVehicle from "@/components/shared/blocks/claimsGateVehicle/claimsGateVehicle.vue";
import BlockDataArray from "@/components/shared/blocks/dataArray/dataArray.vue";
import BlockCarweb from "@/components/shared/blocks/carWeb/carweb.vue";
import BlockVehicleSplitTest from "@/components/shared/blocks/blockVehicleSplitTest/blockVehicleSplitTest.vue";
import BlockNumber from "@/components/shared/blocks/number.vue";
import BlockYoti from "@/components/shared/blocks/utility/yoti/yoti.vue";
import BlockWorkspace from "@/components/shared/blocks/workspaces/workspace.vue";
import BlockNationalInsurance from "@/components/shared/blocks/user/nationalinsurance.vue";
import BlockTextArea from "@/components/shared/blocks/text/textarea.vue";
import BlockLink from "@/components/shared/blocks/link.vue";
import BlockTitle from "@/components/shared/blocks/text/title.vue";
import BlockSubTitle from "@/components/shared/blocks/text/subtitle.vue";
import BlockDynamicProgressBar from "@/components/shared/blocks/dynamicProgressBar.vue";
import BlockParagraph from "@/components/shared/blocks/paragraph.vue";
import BlockTransferWorkspace from "@/components/shared/blocks/transferWorkspace/transferWorkspace.vue";
import BlockCopyClaim from "@/components/shared/blocks/copyClaim/copyClaim.vue";
import BlockTransferFunnel from "@/components/shared/blocks/transferFunnel/transferFunnel.vue";
import BlockIconGroup from "@/components/shared/blocks/groupIcons/iconGroupBlock.vue";
import BlockOtherClaims from "@/components/shared/blocks/otherClaims/otherClaims.vue";
import BlockCreditCheck from "@/components/shared/blocks/checkboardCreditCheck/creditCheck.vue";
import BlockOverdraftReport from "@/components/shared/blocks/armalytix/overdraftReport.vue";
// Ghost components (props only)
import BlockContainer from "@/components/shared/blocks/row.vue";
import BlockSimplePhone from "@/components/shared/blocks/simplePhone.vue";
import BlockSimpleEmail from "@/components/shared/blocks/simpleEmail.vue";
import BlockPlaces from "@/components/claimant/form/places/places.vue";
//UserVariablesBlocks
import BlockUserAddress from "@/components/shared/blocks/user/address/address.vue";

import BlockDateOfBirth from "@/components/shared/blocks/user/dateofbirth.vue";
import BlockDraw from "@/components/shared/blocks/draw.vue";
import BlockName from "@/components/shared/blocks/user/name.vue";
import BlockAgreement from "@/components/shared/blocks/user/agreement.vue";
import BlockEmail from "@/components/shared/blocks/user/email/email.vue";
import BlockPhone from "@/components/shared/blocks/user/phone/phone.vue";
import BlockConsent from "@/components/shared/blocks/user/consent/consent.vue";
import BlockContactDetails from "@/components/shared/blocks/user/contactDetails/contactDetails.vue";
import BlockKyc from "@/components/shared/blocks/user/kyc/kyc.vue";
import BlockDsar from "@/components/shared/blocks/dsar/dsar.vue";
import BlockCarousel from "@/components/shared/blocks/carousel.vue";
import BlockFaq from "@/components/shared/blocks/faq/faq.vue";
import BlockFinanceAgreement from "@/components/shared/blocks/financeAgreement/financeAgreement.vue";
import BlockSectionsProgressBar from "@/components/shared/blocks/sectionsProgressBar/sectionsProgressBar.vue";
import BlockManualIdUpload from "@/components/shared/blocks/manualIdUpload/manualIdUpload.vue";
import BlockComplexQuestion from "@/components/shared/blocks/complexQuestion/complexQuestion.vue";
import BlockNestedMultiSelect from "@/components/shared/blocks/nestedMultiSelect/nestedMultiSelect.vue";
import BlockManualVehicle from "@/components/shared/blocks/manualVehicle/manualVehicle.vue";
import BlockInfoCard from "@/components/shared/blocks/user/infocard.vue";
// Sentry
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

// Plugins
import InfoModal from "@/components/shared/global/informationModal/informationModalPlugin.js";

require("@/additionalStyles.css");

import { initFirebaseBackend, getFirebaseBackend } from "./authUtils";

import VueFacebookPixel from "vue-facebook-pixel";

// Fontawesome icons
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { claimsGateIcons, sgIcons } from "./design/custom/plugins/icons/fontAwesome";
library.add(...claimsGateIcons, ...sgIcons);
dom.watch();
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
Vue.component("font-awesome-icon", FontAwesomeIcon);

// Undraw Illustrations
// import UndrawPersonalSite from "vue-undraw/UndrawPersonalSite";
// Vue.component(UndrawPersonalSite.name, UndrawPersonalSite);

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID,
};

if (getFirebaseBackend() == null) {
  initFirebaseBackend(firebaseConfig);
}

//window.console.log(process.env.VUE_APP_DEFAULT_AUTH)

Vue.use(VueRouter);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMask);
Vue.use(VueSignaturePad);
Vue.use(InfoModal);
Vue.use(x5GMaps, { key: "AIzaSyDINXHfd5QOo14Ey3CnWa20rywpvbLIrjY", libraries: ["places"] });

/*eslint-disable*/
function addToDataLayer() {
  window.dataLayer.push(arguments);
}

Vue.use(VueFacebookPixel, { router });

Vue.use(VueIntercom, { appId: "" });

import { WorkspaceReferralService } from "@/helpers/ClaimsGate/referral/WorkspaceReferralService";

const workspaceReferralService = new WorkspaceReferralService();

const urlParams = new URLSearchParams(window.location.search);
workspaceReferralService.storeReferrerFromLocalStorage(localStorage, urlParams).then(() => {
  window.console.log("Successfully stored referral from URL");
});

window.originalLocation =
  document.location.protocol +
  "//" +
  document.location.hostname +
  document.location.pathname +
  document.location.search;
// ga_includes

if (process.env.VUE_APP_PROJECTId === "claimsgate-4cda5") {
  const ga_config = { id: "G-8D8120WRXQ" };
  Vue.use(
    VueGtag,
    {
      config: ga_config,
      pageTrackerTemplate(to) {
        window.console.log(to);
        return {
          page_location: window.originalLocation,
          page_path: to.path + window.location.search,
        };
      },
    },
    router
  );
}

if (process.env.VUE_APP_RUNNING_FROM_EMULATOR !== "true" && process.env.VUE_APP_PROJECTId === "claimsgate-4cda5") {
  // window.console.log = function () {};
  Sentry.init({
    Vue,
    environment: "production",
    dsn: "https://64cbb4946dc04ca48cec06189f375d34@o1057148.ingest.sentry.io/6043801",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [window.location.host, /^\//],
      }),
      new Sentry.Replay({ stickySession: true, maskAllText: false }),
    ],

    // Replay Settings
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 0.0,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    logErrors: true,
  });
} else {
  Sentry.init({
    Vue,
    environment: "staging",
    dsn: "https://64cbb4946dc04ca48cec06189f375d34@o1057148.ingest.sentry.io/6043801",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["localhost", /^\//],
      }),
      new Sentry.Replay({ stickySession: true, maskAllText: false }),
    ],

    // Replay Settings
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 0.0,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    logErrors: true,
  });
}

// Handle login/logout event from existing tabs
window.addEventListener("storage", (event) => {
  if (event.storageArea != localStorage) return;
  if (
    event.key === "auth.currentUser" &&
    (event.newValue === "null" || event.newValue === null || event.oldValue === "null" || event.oldValue === null)
  ) {
    console.log("detected auth event from existing tabs");
    // force reload the page to make the effects take place immediately
    window.location.reload();
  }
});

// capture errors within Vue
// Vue.config.errorHandler = function(err, vm, info) {
//   swalService
//     .fire("error", {
//       title: "Timed out",
//       text: "Whoops, it looks like your request has encountered an error. Please try again.",
//       icon: "error"
//     })
//     .then(res => {
//       router.push({ name: "Me" });
//     })
//     .catch(exception => {
//       router.push({ name: "Me" });
//     });
// };

import { i18n } from "./i18n";

const app = new Vue({
  router,
  store,
  i18n,

  // This contains each of the components which are used as Blocks in our system
  // It should not contain things like 'RowRender'
  components: {
    // Bootstrap 4
    BlockButton: BlockButton,
    // BlockEmbed: BlockEmbed,
    BlockImage: BlockImage,
    BlockText: BlockText,
    BlockTitle: BlockTitle,
    BlockSubTitle: BlockSubTitle,
    BlockParagraph: BlockParagraph,
    // Bootstrap 4 - Inputs
    BlockPageButtons: BlockPageButtons,
    BlockVideo: BlockVideo,
    BlockInput: BlockInput,
    BlockDropdown: BlockDropdown,
    BlockCheckbox: BlockCheckbox,
    BlockSingleSelect: BlockSingleSelect,
    BlockMultiSelect: BlockMultiSelect,
    BlockYoti: BlockYoti,
    BlockNationalInsurance: BlockNationalInsurance,
    BlockNumber: BlockNumber,
    // Custom
    BlockWorkspace: BlockWorkspace,
    BlockProgressBar: BlockProgressBar,
    BlockHTML: BlockHTML,
    BlockFileInput: BlockFileInput,
    BlockMultiFileInput,
    BlockDatepicker: BlockDatepicker,
    BlockDropdownSearch: BlockDropdownSearch,
    BlockPadding: BlockPadding,
    BlockVehicle: BlockVehicle,
    BlockCaravan: BlockCaravan,
    BlockCompany,
    BlockClaimAddress,
    BlockMotorSpecs,
    BlockRevX,
    BlockCurrency,
    BlockCountry,
    BlockClaimsGateVehicle,
    BlockSectionsProgressBar,
    BlockDynamicProgressBar,
    BlockCarweb,
    BlockVehicleSplitTest,
    BlockKyc,
    BlockDisplayFile,
    BlockIconGroup,
    BlockCarouselWidget,
    BlockOtherClaims,
    BlockCreditCheck,
    BlockOverdraftReport,
    // Ghost
    BlockContainer: BlockContainer,
    BlockPlaces: BlockPlaces,
    //BlockRow: BlockRow,
    //BlockColumn: BlockColumn,
    BlockEmail: BlockEmail,
    BlockPhone,
    BlockConsent,
    BlockUserAddress: BlockUserAddress,
    BlockContactDetails: BlockContactDetails,
    BlockDateOfBirth: BlockDateOfBirth,
    BlockDraw: BlockDraw,
    BlockName: BlockName,
    BlockAgreement: BlockAgreement,
    BlockLink: BlockLink,
    BlockTextArea: BlockTextArea,
    BlockCarousel,
    BlockFaq: BlockFaq,
    BlockFinanceAgreement: BlockFinanceAgreement,
    BlockDsar,
    BlockDataArray,
    BlockSimplePhone,
    BlockSimpleEmail,
    BlockKyb,
    BlockManualIdUpload,
    BlockComplexQuestion,
    BlockNestedMultiSelect,
    BlockTransferWorkspace,
    BlockCopyClaim,
    BlockTransferFunnel,

    BlockAltVehicle,
    BlockManualVehicle,
    BlockInfoCard,
  },
  render: (h) => h(App),
});

// router.onReady(() => {
//   app.$mount("#app");
// });

app.$mount("#app");

global.vm = app;
