import { navigationComputed } from "@/state/helpers";
import { convertTimestampToDate } from "./helpers/convertTimestampToDate";
// types
import type { BaseProps, ViewProps } from "./helpers/CheckboardCreditCheck";
import type { CreditCheckInstance } from "./creditCheckInstance";
import type { Computed } from "vuex";

export function mappedComputed(): Record<keyof typeof navigationComputed, Computed> {
  return {
    ...(navigationComputed as Record<keyof typeof navigationComputed, Computed>),
  };
}

export const computed = {
  currentViewProps,
};

function currentViewProps(state: CreditCheckInstance): ViewProps {
  const baseProps: BaseProps = {
    claimId: state.claimId,
    userId: state.userId,
    checkId: state.checkId,
    check: state.check,
    isBuilder: state.isBuilder,
    padding: state.padding,
    allowCheckboardFallback: state.allowCheckboardFallback,
    useValidateRunCheck: state.useValidateRunCheck,
    headless: state.headless,
  };

  if (state.currentView === "approveCheck") {
    const dob = state.user === null ? "" : convertTimestampToDate(state.user?.dateOfBirth);
    return {
      ...baseProps,
      userFirstName: state.user?.firstName,
      userLastName: state.user?.lastName,
      userDob: dob,
    };
  } else if (state.currentView === "addressConfirmation") {
    return {
      ...baseProps,
      addresses: state.addresses,
      currentAddress: state.currentAddress,
    };
  } else if (state.currentView === "addressHistory") {
    return {
      ...baseProps,
      selectedAddress: state.selectedAddress,
    };
  } else if (state.currentView === "requestorAuthenticity") {
    return {
      ...baseProps,
      questionnaire: state.questionnaire,
    };
  } else if (state.currentView === "confirmLenders") {
    return {
      ...baseProps,
      possibleLenders: state.possibleLenders,
    };
  } else if (state.currentView === "success") {
    return {
      ...baseProps,
      confirmedLenders: state.confirmedLenders,
      confirmedClaimLender: state.confirmedClaimLender,
      stores: state.stores,
    };
  } else {
    return { ...baseProps };
  }
}
