export const financeProviderList = [
  "Morgan Motor Finance",
  "Erwin Hymer Group Finance",
  "Abarth Financial Services",
  "Alfa Preferenza",
  "Alfa Romeo Direct",
  "Alfa Romeo Preferenza",
  "Auto Expert",
  "Automony Choices",
  "Chrysler Financial Services",
  "Chrysler Group Horizon",
  "Chrysler Horizon",
  "FAFS",
  "FGA Capital",
  "FGA Capital Financial Services",
  "Fiat 2-Easy",
  "Fiat Auto Financial Services",
  "Fiat Chrysler Capital",
  "Fiat Chrysler Financial Services",
  "Fiat Easiplan",
  "Fiat Easiplan Equity Plus",
  "Fiat Easiplan Gold",
  "Fiat i-Deal",
  "Fiat Premier Plan",
  "Jaguar Privilege",
  "Jeep Financial Services",
  "Jeep Horizon (PCP)",
  "Lancia Financial Services",
  "Land Rover Freedom",
  "Maserati (PCP)",
  "Maserati Financial Services",
  "Maserati Financial Services UK",
  "Preferenza",
  "FCA Automotive Services UK Ltd",
  "FCA Automotive Services",
  "FCA Automotive Services UK",
  "FCA Automotive Services United Kingdom",
  "Jeep Horizon",
  "Chrysler Group Insurance",
  "Chrysler Insurance",
  "Jeep Insurance",
  "Maserati Insurance",
  "Abarth i-Deal",
  "Abarth Insurance",
  "Alfa Romeo Insurance",
  "Fiat Insurance",
  "Jaguar Chauffeur Insurance",
  "Chrysler Group Financial Services",
  "Chrysler Group Insurance Services",
  "Jaguar Cars Insurance",
  "Land Rover Insurance",
  "Jaguar Cars Finance",
  "Land Rover Finance",
  "Alfa Romeo Financial Services",
  "Fiat Financial Services",
  "Alfa Romeo Consumer Services",
  "Auto Expert Finance",
  "Fiat Consumer Services",
  "Teamsys",
  "Fca Fleet Services Uk Ltd",
  "Stellantis Financial Services UK Limited",
  "Blackhorse",
  "Black Horse Limited",
  "BLACK HORSE LTD",
  "Blackhorse Ltd",
  "Jaguar - Black Horse",
  "Advantage Finance Limited",
  "Aldermore Bank Asset Finance",
  "Alphabet (GB) Limited",
  "Alphera Financial Services",
  "Audi Finance",
  "Barclays Bank SAU",
  "Barclays Partner Finance",
  "Billing Finance Ltd",
  "Blue Motor Finance Limited",
  "BMW Financial Services",
  "Close Brothers Limited",
  "CLOSE BROTHERS RETAIL FINANCE",
  "CLOSE BROTHERS PREMIUM FINANCE",
  "FCE Bank",
  "Vauxhall Finance/ GMAC",
  "Hitachi Capital (UK) Limited",
  "HITACHI CAPITAL VEHICLE SOLUTION",
  "HITACHI CAPITAL CONSUMER FINANCE",
  "HITACHI CAPITAL",
  "Honda Finance Europe plc",
  "Hyundai Capital UK Ltd",
  "HYUNDAI CAR FINANCE LTD",
  "Lex Autolease Limited",
  "Lexus Financial Services",
  "Mercedes-Benz Financial Services UK Limited",
  "Moneybarn",
  "MotoNovo Finance Limited",
  "Oodle Financial Services",
  "PCF Financial Services Limited",
  "PCF Financial Services Limited",
  "PSA Finance UK Limited",
  "RCI Financial Services Limited",
  "Santander Consumer (UK) plc",
  "SANTANDER CONSUMER FINANCE",
  "SANTANDER CONSUMER (UK) PLC (CON",
  "Secure Trust Bank plc",
  "Toyota Financial Services (UK) plc",
  "TOYOTA FINANCIAL SERVICES",
  "TOYOTA FINANCIAL SERVICES (UK) P",
  "Vauxhall Finance plc",
  "Jaguar",
  "Volkswagen Financial Services (UK) Limited",
  "Landrover",
  "N.I.I.B T/A NORTHRIDGE FINANCE",
  "N.I.I.B T/A NORTHRIDGE FINANCE",
  "Clydesdale Finance",
  "SPECIALIST MOTOR FINANCE LTD",
  "SHOGUN FINANCE LTD",
  "RATESETTER COM",
  "SHAWBROOK BANK LIMITED",
  "CREATION FINANCIAL SERVICES LTD",
  "Firstplus",
  "Volvo Financial Services LTD",
  "Suzuki Financial Services Limited",
];
