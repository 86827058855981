<script lang="ts">
import Vue from "vue";
//Helper
import { baseProps } from "../helpers/baseProps";
import { gatewayHelper } from "../helpers/gatewayHelper";
import { viewSubmit } from "../helpers/viewSubmit";
import { handleUnknownError } from "../helpers/handleUnknownError";
//components
import BlockButton from "@/components/shared/blocks/button.vue";
import { formComputed } from "@/state/helpers";
import CheckboardHeaderContent from "./checkboardHeaderContent.vue";

export default Vue.extend({
  name: "Fail",
  components: { BlockButton, CheckboardHeaderContent },
  props: {
    ...baseProps(),
  },
  computed: {
    ...formComputed,
  },
  data() {
    return {
      isSubmitting: false,
    };
  },
  methods: {
    handleSkip() {
      this.$store.dispatch("events/fire", { name: "next" });
    },
    async handleTryAgain() {
      try {
        this.isSubmitting = true;

        const result = await gatewayHelper("checkboardInitiateCheck", {
          claimId: this.claimId,
          userId: this.userId,
          workspaceId: this.check.workspaceId,
          funnelId: this.check.funnelId,
          pageId: this.check.pageId,
          forceNew: true,
          headless: false,
          allowCheckboardFallback: this.allowCheckboardFallback,
          useValidateRunCheck: this.useValidateRunCheck,
        });

        viewSubmit(this, "fail", result);

        this.isSubmitting = false;
      } catch (error) {
        console.error(`Error submitting lenders: ${error}`);

        handleUnknownError(this, this.check);
      } finally {
        this.isSubmitting = false;
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
});
</script>
<style scoped>
.gap-20 {
  gap: 20px;
}
</style>
<template>
  <div class="d-flex flex-column gap-20">
    <CheckboardHeaderContent
      :isBuilder="isBuilder"
      :progressValue="'0'"
      :header="'Unable to complete credit check'"
      :subtitle="`Unfortunately we have been unable to complete your credit check. You may try again by clicking the button below.`"
      :text="`Alternatively you may skip this step and continue with your claim, by clicking the skip button below.`"
    />

    <BlockButton
      :text="'Try Again'"
      :variant="'primary'"
      :isProcessing="isSubmitting"
      @clicked="handleTryAgain"
      :block="false"
      :submitButtonDefault="false"
    />
    <BlockButton
      :text="'Skip'"
      :variant="'secondary'"
      :isProcessing="loaders.next"
      @clicked="handleSkip"
      :block="false"
      :submitButtonDefault="false"
    />
  </div>
</template>
