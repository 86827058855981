import { Account } from "./CheckboardCreditCheck";

export function createClaimStore({
  confirmedClaimLender,
  confirmedLenders,
  variableIds,
  creditService,
}: {
  confirmedClaimLender: Account;
  confirmedLenders: Account[];
  variableIds: { [key: string]: string };
  creditService: "validate" | "checkboard";
}) {
  const claimStore = {};
  console.log("createClaimStore", confirmedLenders, confirmedClaimLender);
  if (confirmedLenders && confirmedLenders.length > 0) {
    claimStore[variableIds.craFinanceAgreements] = confirmedLenders;
  }

  if (confirmedClaimLender) {
    claimStore[variableIds.craFinanceAgreement] = confirmedClaimLender;

    const checkBoardPrimitiveAccountKeys: Array<[string, string | string[]]> = [
      ["craProductType", "type"],
      ["craFinanceLender", "companyName"],
      ["craCompanyType", "companyClass"],
      ["craAgreementNumber", "accountNumber"],
      ["craCreditStartDate", "start"],
      ["craCurrentBalance", ["balance", "current_balance"]],
      ["craCreditEndDate", "end"],
    ];

    const validatePrimitiveAccountKeys: Array<[string, string | string[]]> = [
      ["craProductType", "type"],
      ["craFinanceLender", "companyName"],
      ["craCompanyType", "companyClass"],
      ["craAgreementNumber", "accountNumber"],
      ["craCreditStartDate", "start"],
      ["craCurrentBalance", ["balance", "current_balance"]],
      ["craCreditEndDate", "end"],
      ["craSettlementDate", "settlementDate"],
      ["craAccountStatus", "accountStatus"],
      ["craLengthOfContract", "lengthOfContract"],
      ["craEstimatedDebt", "estimatedDebt"],
    ];

    const primitiveAccountKeys =
      creditService === "checkboard" ? checkBoardPrimitiveAccountKeys : validatePrimitiveAccountKeys;

    primitiveAccountKeys.forEach(([cgClaimKey, cbAccountKey]) => {
      if (Array.isArray(cbAccountKey)) {
        if (confirmedClaimLender[cbAccountKey[0]]?.[cbAccountKey[1]])
          claimStore[variableIds[cgClaimKey]] = confirmedClaimLender[cbAccountKey[0]][cbAccountKey[1]];
      } else if (confirmedClaimLender[cbAccountKey]) {
        claimStore[variableIds[cgClaimKey]] = confirmedClaimLender[cbAccountKey];
      }
    });
  }
  console.log("claimStore", claimStore);
  return claimStore;
}
