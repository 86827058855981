<script lang="ts">
import Vue from "vue";
import { Props } from "@/types";
import BlockInputWrapper from "@/components/shared/blocks/inputwrapper.vue";
import { themesComputed } from "@/state/helpers";
import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";

const inputPropsValue: Props.BlockMultiSelect = inputProps({
  answerType: "array",
  extraProps: ["options", "other"],
});
export default Vue.extend({
  name: "BlockMultiselect",
  components: { BlockInputWrapper },
  props: {
    ...inputPropsValue,
  },
  data() {
    return {
      /**
       * Array of answers emitted by the component
       *  @type { Array } */
      newAnswer: [],
      /**
       * User custom input if Other enabled
       * @type { String }
       */
      otherAnswer: "",
      /**
       * Other option is currently selected
       */
      otherSelected: false,
      /**
       * Used to tick the Other radio box, when other is selected
       * @type { Array<'Other'>}
       */
      otherVModel: [],
    };
  },
  methods: {
    /** @type { (index: number) => void} */
    select(index) {
      this.$emit("update:state", null);

      if (this.other && this.options[index] === "Other") {
        // other has been selected, toggle show other box
        this.otherSelected = !this.otherSelected;

        // remove other answer if it has been deselected
        if (!this.otherSelected) {
          this.removeOtherAnswer();
          this.otherVModel = [];
        } else {
          // Ticks the other box
          this.otherVModel["Other"];
        }

        // re-add input answer - user is toggling Other
        if (this.otherAnswer?.length > 0 && this.otherSelected) {
          this.newAnswer.push(this.otherAnswer);
        }
      } else if (this.newAnswer.includes(this.options[index])) {
        // user has deselected option, remove it from answer
        this.newAnswer.splice(this.newAnswer.indexOf(this.options[index]), 1);
      } else {
        //add answer as long as it is not 'Other'
        this.newAnswer.push(this.options[index]);
      }
      this.$emit("update:answer", this.newAnswer);
    },
    /** @type { (index: number) => Boolean} */
    isSelected(index) {
      if (this.newAnswer.includes(this.options[index])) {
        return true;
      }
      //  else if (this.showOtherInput && this.options.length - 1 === index) {
      //   return true;
      // }
      else {
        return false;
      }
    },
    selectOther() {
      // other has been selected, toggle show other box
      this.$emit("update:state", null);
      this.otherSelected = !this.otherSelected;
      // remove other answer if it has been deselected
      if (!this.otherSelected) {
        this.removeOtherAnswer();
        this.otherVModel = [];
      } else {
        this.otherVModel = ["Other"];
      }

      // re-add exting input answer - user is toggling Other
      if (this.otherAnswer?.length > 0 && this.otherSelected) {
        this.newAnswer.push(this.otherAnswer);
      }
    },
    handleOtherAnswer() {
      console.log("MULTI handling", this.otherAnswer, this.newAnswer);
      this.removeOtherAnswer();

      if (this.otherAnswer?.length > 0) {
        this.newAnswer.push(this.otherAnswer);
        this.$emit("update:answer", this.newAnswer);
      }

      console.log("MULTI handling after", this.otherAnswer, this.newAnswer);
    },
    /** Remove any user input answer */
    removeOtherAnswer() {
      this.newAnswer.forEach((answer, index) => {
        if (!this.options.includes(answer)) {
          this.newAnswer.splice(index, 1);
          this.$emit("update:answer", this.newAnswer);
        }
      });
    },
    mount() {
      // @ts-ignore
      if (this.answer === "") {
        this.newAnswer = [];
        this.$emit("update:answer", []);
      } else if (Array.isArray(this.answer)) {
        this.newAnswer = this.answer;
      } else if (!Array.isArray(this.answer) && this.newAnswer.length > 0) {
        // Error in stored answer for this block
      }

      /**
       * Check if the answer array contains an element that is not in the options. If true, update otherAnswer to match loaded data
       */
      const otherAnswerPresent = (/** @type {string[]} */ options, /** @type {string[]} */ answer) => {
        let otherAnswerPresent = false;
        if (Array.isArray(answer)) {
          answer.forEach((answer) => {
            if (!options.includes(answer)) {
              otherAnswerPresent = true;
              this.otherAnswer = answer;
            }
          });
        }
        return otherAnswerPresent;
      };

      console.log("MULTI pre load answer", this.newAnswer);
      if (this.other && otherAnswerPresent(this.options, this.answer)) {
        this.otherSelected = true;
        this.otherVModel = ["Other"];
        // this.select(this.options.length - 1);
      }
      console.log("MULTI after", this.newAnswer);
    },
  },
  mounted() {
    this.mount();
  },

  computed: {
    ...themesComputed,
  },
});
</script>
<style scoped>
.selected {
  border-radius: 0.25rem;
  border-width: 2px !important;
  border-color: var(--primary) !important;
  transition: 0.25s;
}
.deselected {
  border-radius: 0.25rem;
  border-width: 2px !important;
  border-color: #ced4da !important;
  transition: 0.25s;
}

.invalid-border {
  border-radius: 0.25rem;
  border-width: 2px !important;
  border-color: #ff0404 !important;
  transition: 0.25s;
}

.valid-border {
  border-radius: 0.25rem;
  border-width: 2px !important;
  border-color: #129e0a !important;
  transition: 0.25s;
}
</style>
<template>
  <BlockInputWrapper v-bind="{ info, padding, label, invalidFeedback, state }">
    <b-form-group v-slot="{ ariaDescribedby }" class="mb-0">
      <b-form-checkbox-group
        id="checkbox-group-0"
        :aria-describedby="ariaDescribedby"
        name="flavour-0"
        :required="required"
        :state="state"
        v-model="newAnswer"
        @change="$emit('update:state', null)"
        :ref="'b-multiselect'"
      >
        <b-row class="justify-content-center no-gutters">
          <template v-for="(option, index) in options">
            <b-col cols="12" class="mb-0" :key="option.id">
              <b-card
                no-body
                class="border mb-0 p-1"
                :class="{
                  selected: isSelected(index),
                  deselected: !isSelected(index),
                  'invalid-border': state === false,
                }"
                :ref="'q' + index"
                @click.prevent="select(index)"
              >
                <div
                  :class="`custom-checkbox b-custom-control-${theme.size} d-flex flex-row ${
                    theme.size === 'lg' ? 'p-3 pl-4' : theme.size === 'md' ? 'p-2 pl-3' : 'px-1'
                  }`"
                >
                  <div class="d-flex align-items-center">
                    <b-form-checkbox class="mr-2" :aria-describedby="ariaDescribedby" :value="option"></b-form-checkbox>
                  </div>
                  <span class="font-weight-normal m-0 d-flex align-items-center" :style="theme.textColourStyles">
                    {{
                      $te("blocks.multiSelect." + option.replace(/\.$/, ""))
                        ? $t("blocks.multiSelect." + option.replace(/\.$/, ""))
                        : option
                    }}
                  </span>
                </div>
              </b-card>
            </b-col>
          </template>
        </b-row>
      </b-form-checkbox-group>
      <!--  group for other to be able to tick the radio box, but not have the answer "Other" in answer array -->
      <template v-if="other">
        <b-form-checkbox-group
          id="checkbox-group-1"
          :aria-describedby="ariaDescribedby"
          name="flavour-1"
          :required="required"
          :state="state"
          v-model="otherVModel"
          @change="$emit('update:state', null)"
          :ref="'b-multiselect-2'"
        >
          <b-row class="justify-content-center no-gutters">
            <b-col cols="12" class="mb-0" :key="'other-col'">
              <b-card
                no-body
                class="border mb-0 p-1"
                :class="{
                  selected: otherSelected,
                  deselected: !otherSelected,
                  'invalid-border': state === false,
                }"
                :ref="'q' + (options.length - 1)"
                @click.prevent="selectOther()"
              >
                <div
                  :class="`custom-checkbox b-custom-control-${theme.size} ${
                    theme.size === 'lg' ? 'p-3 pl-4' : theme.size === 'md' ? 'p-2 pl-3' : 'px-1'
                  }`"
                >
                  <b-form-checkbox
                    style="display: inline"
                    class="mr-2"
                    :aria-describedby="ariaDescribedby"
                    :value="otherVModel[0]"
                  ></b-form-checkbox>
                  <span style="display: inline-block" class="font-weight-normal m-0" :style="theme.textColourStyles">
                    Other
                  </span>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-form-checkbox-group>
      </template>
    </b-form-group>
    <b-input-group v-if="otherSelected" :ref="'other'" class="pt-1">
      <b-form-input
        id="input"
        type="text"
        placeholder="Your answer"
        :size="theme.size"
        :required="required"
        :state="state"
        :disabled="disabled"
        v-model="otherAnswer"
        @change="
          $emit('update:state', null);
          handleOtherAnswer();
        "
        aria-describedby="input-live-feedback input-live-help"
        trim
        :class="disabled ? 'disabled' : ''"
      >
      </b-form-input>
    </b-input-group>
  </BlockInputWrapper>
</template>
